<template>
  <div>
    <span class="header-download" @click="toDownload">
      <el-badge
        class="mark"
        :value="downLoadLength !== 0 ? downLoadLength : ''"
      >
        <i class="iconfont its_xiazaizhongxin"></i>
      </el-badge>
    </span>
    <!-- 下载列表 -->
    <el-dialog
      title="下载中心"
      :visible.sync="dialogDownVisible"
      v-if="dialogDownVisible"
      custom-class="downLoad"
      width="50%"
      @close="closedialog"
    >
      <div class="body rank-table">
        <el-tabs type="border-card" v-model="activeName" @tab-click="clickTab">
          <el-tab-pane label="未下载" name="first">
            <el-table
              :data="unDownLoadList"
              style="width: 100%"
              height="47.5vh"
              class="no-blank"
            >
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                prop="fileName"
                label="文件名"
                :width="200 * screenCoefficient"
                :show-overflow-tooltip="true"
              ></el-table-column>

              <el-table-column
                prop="createTime"
                label="生成时间"
                :width="160 * screenCoefficient"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column prop="fileStatus" label="状态">
                <template slot-scope="scope">
                  {{ fileStatus[Number(scope.row.fileStatus)] }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    :disabled="scope.row.fileStatus !== 3"
                    :sort="scope.row.fileStatus !== 3 ? 'grey' : 'primary'"
                    @click="onDownload(scope.row)"
                    >下载到本地</el-button
                  >
                  <!-- <el-button type="text" size="small">重新上传</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="downForm.pageSize"
                :current-page="downForm.currentPage"
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已下载" name="second">
            <el-table
              :data="downLoadList"
              style="width: 100%"
              height="47.5vh"
              class="no-blank"
            >
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                prop="fileName"
                label="文件名"
                :width="200 * screenCoefficient"
                :show-overflow-tooltip="true"
              ></el-table-column>

              <el-table-column
                prop="generateTime"
                label="生成时间"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    sort="primary"
                    @click="onDownload(scope.row, true)"
                    >再次下载</el-button
                  >
                  <!-- <el-button type="text" size="small">重新上传</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="downForm.pageSize"
                :current-page="downForm.currentPage"
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="历史视频" name="third">
            <el-table
              :data="videoList"
              style="width: 100%"
              height="47.5vh"
              class="no-blank"
            >
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                prop="fileName"
                label="文件名"
                :width="320 * screenCoefficient"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  {{
                    `${scope.row.cph}摄像头${scope.row.channelId}_${scope.row.startTime}-${scope.row.endTime}`
                  }}
                </template></el-table-column
              >

              <el-table-column
                prop="startTime"
                label="生成时间"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                :width="100 * screenCoefficient"
                :show-overflow-tooltip="true"
                :formatter="formatTip"
              >
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    :disabled="scope.row.status !== 0"
                    :sort="scope.row.status !== 0 ? 'grey' : 'primary'"
                    @click="downLoadCheck(scope.row)"
                    >下载到本地</el-button
                  >
                  <!-- <el-button type="text" size="small">重新上传</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  queryExcelExportList,
  updateExcelExport,
  getHistoryVideoList
} from '@/api/lib/refreshAlarm.js'

export default {
  name: 'Avatar',
  computed: {
    ...mapGetters({
      isRefreshLive: 'getRefresh',
      downLoadLength: 'downLoadLength',
      screenCoefficient: 'screenCoefficient'
    })
  },
  data() {
    return {
      dialogDownVisible: false,
      activeName: 'first',
      downForm: {
        currentPage: 1,
        pageSize: 10
      },
      unDownLoadList: [],
      downLoadList: [],
      videoList: [],
      circleDownLoadTime: 20 * 1000, // 20秒获取一次数据,更新状态

      fileStatus: ['', '等待生成中', '生成中', '上传完成', '上传失败', '已删除']
    }
  },
  methods: {
    /**下载列表 */
    toDownload() {
      this.getUnDownLoadList(true)
    },
    getUnDownLoadList(flag) {
      let data = {
        currentPage: this.downForm.currentPage,
        pageSize: this.downForm.pageSize,
        fileStatusList: [1, 2, 3, 4]
      }
      queryExcelExportList(data).then((res) => {
        if (res.code === 1000) {
          this.unDownLoadList = res.data.list
          this.total = res.data.total
        } else {
          this.unDownLoadList = []
          this.total = 0
        }
        if (flag) this.dialogDownVisible = true
      })
    },
    getDownLoadList() {
      let data = {
        currentPage: this.downForm.currentPage,
        pageSize: this.downForm.pageSize,
        fileStatusList: [5]
      }
      queryExcelExportList(data).then((res) => {
        if (res.code === 1000) {
          this.downLoadList = res.data.list
          this.total = res.data.total
        } else {
          this.downLoadList = []
          this.total = 0
        }
      })
    },
    /**切换未下载|已下载 */
    clickTab(tab, event) {
      this.total = 0
      this.downForm.currentPage = 1
      this.downForm.pageSize = 10
      this.unDownLoadList = []
      this.downLoadList = []
      switch (tab.name) {
        case 'first':
          this.getUnDownLoadList()
          break
        case 'second':
          this.getDownLoadList()
          break
        case 'third':
          this.getVideoDownload()
          break
        default:
          break
      }
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.downForm.currentPage = page
      switch (this.activeName) {
        case 'first':
          this.getUnDownLoadList()
          break
        case 'second':
          this.getDownLoadList()
          break

        default:
          break
      }
    },
    // 当前条数改变
    onSizeChange(size) {
      this.downForm.pageSize = size
      this.downForm.currentPage = 1
      switch (this.activeName) {
        case 'first':
          this.getUnDownLoadList()
          break
        case 'second':
          this.getDownLoadList()
          break

        default:
          break
      }
    },
    /**下载|导出 */
    onDownload(row, flag) {
      updateExcelExport({
        id: row.id,
        fileStatus: 5,
        filePath: row.filePath
      }).then((res) => {
        if (res.code === 1000) {
          let url = res.data
          const elt = document.createElement('a')
          elt.setAttribute(
            'href',
            `${url}?response-content-type=application/octet-stream`
          )
          elt.download = res.fileName
          elt.style.display = 'none'
          document.body.appendChild(elt)
          elt.click()
          document.body.removeChild(elt)
          if (!flag) this.toDownload()
        }
      })
    },
    /**下载历史视频*/
    downLoadCheck(res) {
      let url = res.filePath
      const elt = document.createElement('a')
      elt.setAttribute(
        'href',
        `${url}?response-content-type=application/octet-stream`
      )
      // elt.download = res.fileName
      elt.style.display = 'none'
      document.body.appendChild(elt)
      elt.click()
      document.body.removeChild(elt)
    },
    /** 获取未下载(1.等待生成中，2.生成中)列表 */
    getDownloadLength() {
      let self = this
      let data = {
        currentPage: 1,
        pageSize: 999,
        fileStatusList: [1, 2]
      }
      queryExcelExportList(data).then((res) => {
        if (res.code === 1000) {
          self.$store.commit('set_downLoadListLength', res.data.total)
          self.$store.dispatch('setRefreshStatus', false)
        }
      })
    },
    /** 获取历史视频下载列表  -1-失败 0-上传成功,  2-终端收到上传指令*/
    getVideoDownload() {
      getHistoryVideoList().then((res) => {
        this.videoList = res.data
      })
    },
    /** 默认显示- */
    formatTip(row, column, cellValue) {
      let data = ''
      switch (cellValue) {
        case 0:
          data = '完成上传'
          break
        case -1:
          data = '终端上传失败'
          break
        case 2:
          data = '终端上传中'
          break
        default:
          break
      }
      return data
    },
    /* 定时获取下载列表 20秒 */
    getDownLoadListStatus() {
      var circleTime = this.circleDownLoadTime // 轮询时间间隔(ms)
      const timer = setInterval(() => {
        if (!this.dialogDownVisible) return
        switch (this.activeName) {
          case 'first':
            this.getUnDownLoadList()
            break
          case 'second':
            this.getDownLoadList()
            break
          case 'third':
            this.getVideoDownload()
            break
          default:
            break
        }
      }, circleTime)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    closedialog() {
      this.activeName = 'first'
    }
  },
  watch: {
    'isRefreshLive.refreshDownLoad'(newvalue) {
      if (newvalue === true) {
        this.getDownloadLength()
      }
    }
  },
  mounted() {
    this.getDownLoadListStatus()
  }
}
</script>
<style lang="scss" scoped>
.header-download {
  display: inline-block;
  width: 2vw;
  height: 100%;
}
</style>
