<template>
  <div class="header">
    <div class="header-left">
      <i
        class="iconfont"
        :class="isCollapse ? 'its_caidanzhankai' : 'its_caidanguanbi'"
        @click="changeCollapse"
      ></i>
      <i class="iconfont its_home" @click="toHomePage"></i>
    </div>

    <div class="header-center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header-serach">
      <!-- <el-input v-model="headerSerach" placeholder="搜索..."></el-input> -->
    </div>
    <div class="header-right">
      <header-download></header-download>
      <div class="header-user-con">
        <!-- 用户名下拉菜单 -->
        <el-dropdown>
          <!-- 用户头像 -->
          <p class="user-avator">
            <img
              src="../../assets/images/home/header.png"
              alt
              class="header_Img"
              srcset
            />
            <span class="user-name">
              <span>{{ username }}</span>
              <i class="el-icon-more el-icon--right"></i>
            </span>
          </p>
          <el-dropdown-menu slot="dropdown" trigger="click">
            <el-dropdown-item @click.native="toAlarm(true)">
              <i class="iconfont its_baojingdanchuangshezhi"></i>
              报警弹窗设置
            </el-dropdown-item>
            <el-dropdown-item @click.native="toText">
              <i class="iconfont its_tiaoduwenbenshezhi"></i>
              调度文本设置
            </el-dropdown-item>
            <el-dropdown-item @click.native="toUpdate">
              <i class="iconfont its_xiugaimima"></i>
              修改密码
            </el-dropdown-item>
            <!-- <el-dropdown-item @click.native="toHead">
              <i class="iconfont its_xiugaitouxiang"></i>
              修改头像
            </el-dropdown-item> -->
            <el-dropdown-item @click.native="logOut">
              <i class="iconfont its_tuichu"></i>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 报警弹窗设置 -->
    <el-dialog
      title="报警弹窗设置"
      :visible.sync="dialogAlarmVisible"
      width="30%"
    >
      <div class="body">
        <el-form
          :model="alarmForm"
          ref="alarmForm"
          label-width="100px"
          label-position="left"
          class="demo-form dialog-form demo-dialog-form"
        >
          <el-form-item label="车辆上车提醒：" prop="vehicleUp">
            <el-switch v-model="alarmForm.vehicleUp"></el-switch>
          </el-form-item>
          <el-form-item label="车辆下车提醒：" prop="vehicleDown">
            <el-switch v-model="alarmForm.vehicleDown"></el-switch>
          </el-form-item>
          <el-form-item label="全局报警弹窗：" prop="globalWindow">
            <el-switch v-model="alarmForm.globalWindow"></el-switch>
          </el-form-item>
          <el-form-item label="全局报警声音：" prop="globalVoice">
            <el-switch v-model="alarmForm.globalVoice"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogAlarmVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmAlarm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 调度文本设置 -->
    <el-dialog
      title="调度文本设置"
      :visible.sync="dialogTextVisible"
      width="50%"
    >
      <div class="body">
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="新增模板" name="first">
            <el-form
              :model="textForm"
              :rules="textRules"
              ref="textForm"
              label-width="90px"
              label-position="left"
              class="demo-form dialog-form"
            >
              <el-form-item label="模板名称：" prop="name">
                <el-input
                  type="text"
                  v-model="textForm.name"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="详情：" prop="detail">
                <el-input
                  type="textarea"
                  v-model="textForm.detail"
                  maxlength="255"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="模板列表" name="second">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="name" label="模板名称">
                <template slot-scope="scope">
                  <el-input
                    v-if="isEdit && nowId === scope.row.id"
                    v-model="scope.row.name"
                    placeholder="请输入模板名称"
                  ></el-input>
                  <span v-else>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="detail" label="详情">
                <template slot-scope="scope">
                  <el-input
                    v-if="isEdit && nowId === scope.row.id"
                    v-model="scope.row.detail"
                    placeholder="请输入详情"
                  ></el-input>
                  <span v-else>{{ scope.row.detail }}</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleEdit(scope.row)"
                    type="text"
                    size="small"
                    v-if="!isEdit"
                    >编辑</el-button
                  >
                  <el-button
                    @click="handleSave(scope.row)"
                    type="text"
                    size="small"
                    v-if="isEdit"
                    >保存</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer" v-if="activeName === 'first'">
        <el-button
          size="small"
          type="default"
          @click="dialogTextVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmText"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改头像 -->
    <el-dialog title="修改头像" :visible.sync="dialogHeadVisible" width="30%">
      <div class="body">
        <p>图片上传</p>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <span
            v-if="imageUrl"
            class="el-upload-action"
            @click.stop="handleRemove()"
          >
            <i class="el-icon-delete"></i>
          </span>
          <i v-else class="el-icon-upload2 avatar-uploader-icon" stop></i>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogHeadVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmHead"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <div class="body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              type="password"
              v-model.trim="ruleForm.oldPassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              v-model.trim="ruleForm.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model.trim="ruleForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="confirmUpdatePsw('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  loginOut,
  getAlarm,
  updateAlarm,
  updatePassword,
  addMessageTemplate,
  editMessageTemplate,
  queryMessageTemplateList
} from '@/api/lib/api.js'
import { checkPassword } from '@/common/utils/index.js'
import md5 from 'js-md5'
import download from './components/download'

export default {
  components: {
    HeaderDownload: download
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePassRepeat = (rule, value, callback) => {
      if (value === this.ruleForm.oldPassword) {
        callback(new Error('新密码不能等于旧密码!'))
      } else {
        callback()
      }
    }
    return {
      total: 0,
      breadList: [],
      nowId: null,
      activeName: 'first',
      isEdit: false,
      dialogVisible: false,
      dialogAlarmVisible: false,
      dialogTextVisible: false,
      dialogHeadVisible: false,
      dialogDownVisible: false,
      username: '',
      headerSerach: '',
      imageUrl: '',
      tableData: [],

      alarmForm: {
        id: null,
        vehicleUp: null,
        vehicleDown: null,
        globalWindow: null,
        globalVoice: null
      },
      textForm: {
        name: null,
        detail: null
      },
      ruleForm: {
        oldPassword: '',
        password: '',
        checkPass: '',
        userId: ''
      },

      isCollapse: false,
      textRules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        detail: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
          { validator: validatePassRepeat, trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'change' }
        ],
        checkPass: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    logOut() {
      this.$confirm('确认退出登录？', '提示')
        .then((_) => {
          this.confirmLogout()
        })
        .catch((_) => {})
    },
    confirmLogout() {
      loginOut({ token: sessionStorage.getItem('token') })
        .then((res) => {
          if (res.code === 1000) {
            localStorage.removeItem('vuex')
            this.$store.dispatch('clear_all')
            this.$store.dispatch('clear_MenuCtr')
            this.$store.dispatch('clear_user')
            this.$store.dispatch('clear_Menus')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('realname')
            this.$router.push({ path: '/login' })
            this.$yhsdp.CloseSocketLink()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectMenu(v) {
      console.log(v)
    },
    toUpdate() {
      this.dialogVisible = true
      if (this.$refs.ruleForm !== undefined) {
        this.$refs.ruleForm.resetFields()
      }
    },
    toAlarm(status) {
      getAlarm({ id: sessionStorage.getItem('userId') }).then((res) => {
        if (res.code === 1000) {
          if (status) {
            this.alarmForm = res.data
            this.dialogAlarmVisible = true
          }
          localStorage.setItem('onlineRemind', res.data.vehicleUp)
          localStorage.setItem('outlineRemind', res.data.vehicleDown)
          localStorage.setItem('showAlarm', res.data.globalWindow)
          localStorage.setItem('showAlarmVoice', res.data.globalVoice)
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    toText() {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
      this.dialogTextVisible = true
    },
    handleEdit(row) {
      this.nowId = row.id
      this.isEdit = true
    },
    handleSave(row) {
      const data = {
        id: row.id,
        name: row.name,
        detail: row.detail
      }
      editMessageTemplate(data).then((res) => {
        if (res.code === 1000) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '修改成功'
          })
          this.isEdit = false
          this.toText()
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    toHead() {
      this.dialogHeadVisible = true
    },
    confirmAlarm() {
      updateAlarm(this.alarmForm).then((res) => {
        if (res.code === 1000) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '修改成功'
          })
          this.dialogAlarmVisible = false
          localStorage.setItem('onlineRemind', this.alarmForm.vehicleUp)
          localStorage.setItem('outlineRemind', this.alarmForm.vehicleDown)
          localStorage.setItem('showAlarm', this.alarmForm.globalWindow)
          localStorage.setItem('showAlarmVoice', this.alarmForm.globalVoice)
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    confirmText() {
      this.$refs.textForm.validate((valid) => {
        if (valid) {
          addMessageTemplate(this.textForm).then((res) => {
            if (res.code === 1000) {
              this.$message({
                type: 'success',
                showClose: true,
                message: '新增成功'
              })
              this.toText()
            } else {
              this.$message({
                type: 'error',
                showClose: true,
                message: res.msg
              })
            }
          })
        }
      })
    },
    confirmHead() {},
    confirmUpdatePsw(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updatedPwd()
        } else {
          return false
        }
      })
    },

    updatedPwd() {
      const data = {
        oldPassword: md5(this.ruleForm.oldPassword),
        newPassword: md5(this.ruleForm.checkPass)
      }
      updatePassword(data).then((res) => {
        if (res.code === 1000) {
          this.$message.success('修改成功')
          // 退出登录
          localStorage.removeItem('vuex')
          this.$store.commit('clear_all')
          this.$store.commit('clear_MenuCtr')
          this.$store.commit('clear_user')
          this.$store.commit('clear_Menus')
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('username')
          sessionStorage.removeItem('menuList')

          this.$router.push({ path: '/login' })
          this.$yhsdp.CloseSocketLink()
        } else {
          this.$message.error('修改失败')
        }
      })
    },
    // 移除图片
    handleRemove() {
      this.imageUrl = ''
    },
    // 上传成功回调
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const type =
        file.type === 'image/jpeg' || 'image/jpg' || 'image/webp' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!type) {
        this.$message.error('图片格式不正确!(只能包含jpg，png，webp，JPEG)')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return type && isLt2M
    },
    onDarkTheme() {
      this.$addStorageEvent(1, 'YH_BF_THEME', 'theme-dark')
    },
    onLightTheme() {
      this.$addStorageEvent(1, 'YH_BF_THEME', 'theme-light')
    },
    isHome(route) {
      return route.path.indexOf('dashboard') > -1
    },
    getBreadcrumb() {
      this.breadList =
        this.$route.matched.filter((item) => item.meta.title) || []
    },
    changeCollapse() {
      this.isCollapse = !this.isCollapse
      this.$bus.$emit('isCollapse', this.isCollapse)
    },
    toHomePage() {
      this.$router.push('/platform')
    }
  },
  created() {
    this.username = sessionStorage.getItem('realname')
    this.getBreadcrumb()
    this.toAlarm(false)
  },
  mounted() {},
  watch: {
    $route() {
      this.getBreadcrumb()
    },
    'isRefreshLive.refreshDownLoad'(newvalue) {
      if (newvalue === true) {
        this.getDownloadLength()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
}
.header-left {
  flex: 1;
  display: flex;
  justify-content: space-around;
  .iconfont {
    font-size: 22px;
  }
}
.header-center {
  flex: 10;
}
.header-serach {
  flex: 2;
}
.collapse-btn {
  flex: 2;
  padding: 0 21px;
  cursor: pointer;
  line-height: 60px;
}
.header-right {
  // flex: 1;
  display: flex;
  height: 3.5vh;
  align-items: center;
  justify-content: center;
  .header-user-con {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  >>> .el-dropdown {
    color: #fff;
  }
  .user-avator {
    cursor: pointer;
    font-size: 16px;
    color: #606266;
    text-align: right;
    display: flex;
    align-content: center;
    align-items: center;
    & > img {
      height: 3.5vh;
      width: 3.5vh;
      border-radius: 50%;
    }
    & > .user-name {
      height: 3.5vh;
      display: flex;
      align-content: center;
      align-items: center;
      & > span {
        height: 3.5vh;
        line-height: 3.5vh;
        max-width: 5vw;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .el-icon-more {
    margin-left: 20px;
  }
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
/deep/.demo-dialog-form .el-form-item__content {
  line-height: 32px;
  height: 32px;
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/images/home/header.png') no-repeat;
  background-size: 100% 100%;
}
.avatar-uploader-icon {
  font-size: 0;
  color: #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0.3);
}
.avatar {
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 120px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
